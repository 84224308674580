body, html {
  background-color: #FFFFFF;

}
.App {
  text-align: center;
  min-height: 100vh;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(6px + 2vmin);
  color: #4A4A4A;
}